
import apiService from "@/services/apiService";

export default {
    methods: {
      filtrarBusinesses(businesses) {
        this.filteredBusinesses = businesses;
      },
      async showMore(businessId) {
        try {
          const checkCadastroResponse = await apiService.checkCadastro(this.token, this.urlEnv);
          if (checkCadastroResponse.data.status) {
            await apiService.getBusinessDetail(businessId);
            this.$router.push({ name: "ViewBusiness", params: { businessId } });
            localStorage.setItem("negocio", businessId);
          } else {
            this.$router.push({ name: "templateRegister" });
            localStorage.setItem("negocio", businessId);
          }
        } catch (error) {
          console.error(error);
        }
      },
      async getListagem() {
        try {
          const response = await apiService.getBusinesses(this.token, this.urlEnv);
          this.businesses = response.data.data.negocios;
          this.filteredBusinesses = this.businesses;
          this.areasDisponiveis();
        } catch (error) {
          console.error(error);
        }
      },
      areasDisponiveis() {
        const names = new Set();
        const groupedAreas = this.businesses.reduce((acc, obj) => {
          const name = obj.area?.nome;
          if (name && !names.has(name)) {
            names.add(name);
            acc.push({
              id: obj.area.id,
              text: name,
              value: obj.area.id,
            });
          }
          return acc;
        }, []);
        this.areasDeInvestimento = groupedAreas;
      },
      getProgressBarColor(negAreaId) {
        switch (negAreaId) {
          case 1:
            return 'desenvolvimento_social';
          case 2:
            return 'educacao_e_cultura';
          case 3:
            return 'meio_ambiente';
          default:
            return 'black'; 
        }
      },
      getNegAreaDescription(neg_area_id) {
        switch (neg_area_id) {
            case 1:
                return "Desenvolvimento social";
            case 2:
                return "Educação e cultura";
            case 3:
                return "Meio ambiente";
            default:
                return "Área desconhecida"; // Caso de valor não mapeado
        }
      },
      getGradient(negAreaId) {
        switch (negAreaId) {
          case 1:
            return 'to top, #032A5A , transparent 40%'; 
          case 2:
            return 'to top, #d64d7a, transparent 40%'; 
          case 3:
            return 'to top, #14AA95, transparent 40%';
          default:
            return 'to top, black, transparent 40%';
        }
      },
      calcPercentInvestiment(business){
        if(business.valor_a_ser_captado && business.valor_ja_contratado)
          return 100*(business.valor_ja_contratado) / business.valor_a_ser_captado;
        else return 0;
      },
      async getBusinessDetail(businessId) {
        try {
          const negocio = await apiService.getBusinessDetail(businessId);
          this.negocio = negocio.data.data.negocio;
          this.capital = negocio.data.data.negocio.titular.pessoa_juridica;
          this.updateDesserts(negocio.data.data.negocio);
        } catch (error) {
          console.error(error);
        }
      },
      async getBusinessDetailMobile(businessId) {
        try {
            const checkCadastroResponse = await apiService.checkCadastro(this.token, this.urlEnv);
            if (checkCadastroResponse.data.status) {
            await apiService.getBusinessDetail(businessId);
            this.$router.push({ name: "ViewBusinessMobile", params: { businessId } });
            localStorage.setItem("negocio", businessId);
            } else {
            this.$router.push({ name: "TemplateRegisterMobile" });
            localStorage.setItem("negocio", businessId);
            }
        } catch (error) {
            console.error(error);
        }
    },
      updateDesserts(negocio) {
        const translate = {
          'Valor Total': 'valor_a_ser_captado',
          'Número de parcelas': 'numero_parcelas',
          'Taxa de rentabilidade': 'taxa',
          'Aporte mínimo': 'ticket_minimo',
          'Garantias': 'garantias',
          'Mínimo de captação da oportunidade': 'valor_minimo',
          'Data limite para reserva': 'data_limite',
          'Data prevista para transferência dos recursos': 'prev_desembolso'
        };
        this.desserts.forEach(dessert => {
          const value = translate[dessert.name];
          if (value) {
            if (['valor_a_ser_captado', 'ticket_minimo', 'valor_minimo'].includes(value)) {
              if(value == 'valor_a_ser_captado'){
                dessert.category = this.formatCurrency(Math.floor(parseFloat(negocio[value])/ 1000) * 1000);
              }else{
                dessert.category = this.formatCurrency(negocio[value]);
              }
            } else if (['data_limite', 'prev_desembolso'].includes(value) && negocio[value]) {
              dessert.category = this.formatDate(negocio[value]);
            } else if (value === 'taxa') {
              dessert.category = this.formatProfitability(negocio[value]);
            } else {
              dessert.category = negocio[value];
            }
          }
        });
      },
      formatProfitability(value) {
        const number = parseFloat(value);

        if (number >= 0.01) {
            // Para valores maiores ou iguais a 0.01, mostrar com 2 casas decimais
            return `${number.toFixed(2).replace('.', ',')}% a.m.`;
        } else {
            // Para valores menores que 0.01, mostrar até 5 casas decimais
            const formatted = number.toFixed(8).replace('.', ',');
            // Remover zeros desnecessários à direita, mas manter se forem significativos
            return `${formatted.replace(/,?0+$/, '')}% a.m.`;
        }
      },
      async getInvestiments() {
        try {
          const response = await apiService.getInvestiments(this.token, this.urlEnv);
          console.log(response)
          this.desserts = Object.values(response.data.data.investimentos).map(item => {
            return {
              ...item,
              valor_total_projeto: this.formatCurrency(item.valor_total_projeto),
              prazo: this.formatPrazo(item.prazo),
              valor_investido: this.formatCurrency(item.valor_investido),
              taxa_retorno: this.formatPercentage(item.taxa_retorno),
              data_inicio: this.formatDate(item.data_inicio),
            };
          });
        } catch (error) {
          console.error(error);
        }
      },
      isDateExceeded(limitDate) {
        if(!limitDate){
          return true;
        }
        const limitDateObj = new Date(limitDate+ 'T00:00:00');
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return today > limitDateObj;
      },
      cantInvestMethod(negocio){
        return parseFloat(negocio.valor_ja_contratado) >= Math.floor(parseFloat(negocio.valor_a_ser_captado)/ 1000) * 1000 
        || negocio.status?.codigo != '02' 
        || this.isDateExceeded(negocio?.data_limite);
      }
    },
    computed: {
      cantInvest(){
        return parseFloat(this.negocio.valor_ja_contratado) >=  Math.floor(parseFloat(this.negocio.valor_a_ser_captado)/ 1000) * 1000 
        || this.negocio.status?.codigo != '02'
        || this.isDateExceeded(this.negocio?.data_limite);
      },
      formattedCNPJ() {
        const cnpj = this.negocio.cnpj_cpf;
        if (cnpj) {
          const numericCNPJ = cnpj.replace(/\D/g, "");
          const cnpjMask = "##.###.###/####-##";
          let formattedCNPJ = "";
          let index = 0;
          for (let i = 0; i < cnpjMask.length; i++) {
            if (cnpjMask[i] === "#") {
              formattedCNPJ += numericCNPJ[index] || "";
              index++;
            } else {
              formattedCNPJ += cnpjMask[i];
            }
          }
  
          return formattedCNPJ;
        }
  
        return cnpj;
      },
    }
  };