<template>
    <v-container fluid class="customize-container">
        <v-dialog max-width="400" v-model="dialog">
            <PjNotification @close="close" />
          </v-dialog>
        <v-row>
            <v-col cols="6" class="px-xl-16 px-lg-10 px-10 left-col">
                <div class="mt-xl-6" style="display: flex; align-items: center;">
                  <ArrowBackButton />
                </div>
                <ProfileCard 
                    imageSrc="@/assets/viniciusBelat.png"
                    profileText= "O <b>Investimento em impacto positivo</b> vai muito além das práticas ambientais, sociais e
                            de governança.
                            Enquanto ESG foi um importante avanço, o conceito pode se aplicar tanto a empresas de
                            impacto
                            negativo como positivo. E ai pode surgir uma nuvem de confusão e imprecisões."
                    detailedProfileText="Eu te afirmo: nossa carteira de investimentos na Belat é 100% de
                            impacto positivo, buscamos retorno financeiro acima do CDI e com nossa transparência total
                            você sabe onde o seu dinheiro é aplicado - sem letrinha miúda no fim da página. E mais, com
                            a gente, você diversifica seus investimentos, porque atuamos nas três áreas essenciais e
                            estruturantes da economia: meio ambiente, desenvolvimento social e educação e cultura."
                    footerText="Vinicius Costa. Head de Relação
                        com Investidores, da Banca Ética"
                />
            </v-col>


            <v-col cols="6" class="right-col" style="display: flex; justify-content: center; align-items: center">
                <div class="pa-xl-16 pa-lg-10 mx-lg-16 pa-lg-16 mx-xl-16">
                    <p class="mt-xl-16 font-weight-regular white--text">Olá, {{ user.name }}, percebemos seu interesse em conhecer mais as nossas oportunidades de investimento e vimos que você se cadastrou através de sua Pessoa Jurídica.</p>
                    <p class="mt-5 white--text">Se você quiser seguir com seu perfil, PJ, por favor clique no botão abaixo que nossa equipe de Relacionamento com Investidores entrará em contato através do seu email cadastrado.</p>
                    <p class="white--text">Agora, se você quiser conhecer os detalhes das oportunidades e começar imediatamente sua jornada de investimento consciente, pedimos que crie um novo cadastro como Pessoa Física.</p>
                    <div class="d-flex justify-center">
                        <v-btn rounded color="secondary" class="px-lg-16 font-weight-bold" style="text-transform: initial;" @click="dialog = true">
                            Quero manter meu cadastro PJ
                        </v-btn>
                    </div>
                    <div class="mt-3 d-flex justify-center">
                        <v-btn @click="returnSignUp" rounded color="button" dark class="px-lg-15 font-weight-bold" style="text-transform: initial;">
                            Criar um novo cadastro para PF
                        </v-btn>
                    </div>
                    <p class="mt-10 white--text text-center">Saiba mais sobre a Banca Ética em nossas redes sociais</p>
                    <SocialMediaLinks/>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import cryptoMixin from "@/mixins/cryptoMixin";
import PjNotification from "../Modal/PjNotification.vue";
import axios from "axios";
import ArrowBackButton from "./Components/ArrowBackButton.vue";
import SocialMediaLinks from "./Components/SocialMediaLinks.vue";
import ProfileCard from "./Components/ProfileCard.vue";
export default {
    components: {
        PjNotification,
        ArrowBackButton,
        ProfileCard,
        SocialMediaLinks
    },
    mixins: [cryptoMixin],
    data() {
        return {
            dialog: false,
            token: localStorage.getItem("token"),
            urlEnv: process.env.VUE_APP_SERVICE_URL,
            user: {
                name: 'Sem nome'
            }
        };
    },
    mounted() {
        const encryptedUser = localStorage.getItem('encryptedUser');
        if(encryptedUser) this.user = JSON.parse(this.decrypt(encryptedUser));
        if (this.token) this.sendUserToLeads();
    },
    methods: {
        close() {
        this.dialog = false;
        },
        returnSignUp() {
            this.$router.push({
              name: "Cadastrar",
            });
        },
        async sendUserToLeads() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            const response = await axios.get(this.urlEnv + "user", config);
            this.user = response.data;
            axios.post(this.urlEnv + "createLead", {
                email: response.data.email,
                name: response.data.name,
                phone_number: response.data.phone_number
            }, config).then(() => {
            }).catch(error => {
                console.error(error);
            })
        }
    }
};
</script>

<style scoped>
.text-h4,
.text-xl-h4,
.text-xl-h5,
.text-h3,
.text-h5 .text-h6,
.white--text,
.text-center,
.d-flex {
    font-family: 'Raleway', cursive !important;
}

.left-col {
    height: 100vh;
    background-color: #14AA95;
}

.right-col {
    height: 100vh;
    background-color: #00265b;
}

.customize-container {
    padding: 0;
    max-width: 1440px;
    margin: 0 auto;
    border: 1px solid #fff;
}

@media (min-width: 2560px) {}

@media (min-width: 1920px) and (max-width: 2559px) {
    .imageVcard {
        z-index: 9;
        /* position: absolute;
        top: 6rem;
        right: 65rem; */
    }
}

@media (min-width: 1280px) and (max-width: 1919px) {
    .imageVcard {
        z-index: 9;
        /* position: absolute;
        top: 0;
        right: 43rem; */
    }
}

@media (max-width: 1279px) {}
</style>