<template>
  <div>
    <layout>
      <template v-slot:content>
        <div class="d-flex justify-center mt-5">
          <h2 class="text-center primary--text">{{ $t('my_investments') }}</h2>
        </div>
        <v-data-table :page.sync="page" :headers="headers" :items="desserts"
          class="mt-10 px-5 custom-data-table" hide-default-footer mobile-breakpoint="xs"
          @page-count="pageCount = $event">
          <template slot="no-data">
            {{ $t('no_investments_found') }}
          </template>
        </v-data-table>
      </template>
    </layout>
  </div>
</template>

<script>
import layout from "@/layouts/internalLayout.vue";
import formatMixin from "@/mixins/formatMixin";
import businessMixin from "@/mixins/businessMixin";

export default {
  metaInfo() {
    return {
      title: 'BELat - Meus Investimentos'
    };
  },
  components: {
    layout,
  },
  mixins: [formatMixin, businessMixin],
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 9,
      userId: localStorage.getItem("userId"),
      headers: [
        { text: this.$t('start_date'), value: "data_inicio" },
        { text: this.$t('business'), align: "start", sortable: false, value: "nome_projeto" },
        { text: this.$t('rate'), value: "taxa_retorno" },
        { text: this.$t('deadline'), value: "prazo" },
        { text: this.$t('investiment_value'), value: "valor_investido" },
        { text: this.$t('status'), value: "status.nome" },
        { text: 'CCB', value: "ccb" },
      ],
      desserts: [],
      token: localStorage.getItem("token"),
      urlEnv: process.env.VUE_APP_SERVICE_URL,
    };
  },
  mounted() {
    this.getInvestiments();
  },
};
</script>

<style>
.custom-data-table .v-data-table-header {
  background-color: #00265b;
}

.text-start {
  font-family: 'Raleway';
}

th > span {
  font-family: 'Raleway';
  color: white;
}

.custom-data-table .v-data-table-header th .v-icon {
  color: white !important;
}

.custom-data-table tbody {
  background-color: #EDF0F3;
}
</style>
