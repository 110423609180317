<template>
  <v-container class="investment-opportunity" ref="pdfContent">
    <v-row class="header">
      <v-col cols="12" class="text-center">
        <img style="max-width: 150px; display: flex; justify-content: start;" src="../../../src/assets/banca_etica.png">
        <h1 style="text-align: center; color: #00a699; font-size: 2.5rem; font-weight: 300; letter-spacing: .3rem">
          Oportunidade de Investimento</h1>
      </v-col>
    </v-row>
    <!-- {{ business.area.imagem }} -->
    <v-row>
      <v-col cols="6">
        <p class="text-white" style="color: #00a699; margin-left: 3rem;">IMPACTO POSITIVO</p>
        <div style="border-bottom: 2px solid #00a699; width: 5rem; margin-bottom: 10px; margin-left: 3rem;"></div>
        <div style="display: flex; justify-content: space-between; height: 65%; margin-left: 3rem;">
          
            <v-img  style="height: 100%;" contain :src="getPhoto(business.area.imagem)"></v-img>
            <v-img  style="height: 100%;"  contain :src="getPhoto(business.setor.imagem)"></v-img>
            <v-img v-if="business.ods.imagem" style="height: 100%; max-width: 11rem;" contain :src="getPhoto(business.ods.imagem)"></v-img>
          
        </div>
        <!-- <div style="display: flex; margin-top: 10px; justify-content: center;">
        </div> -->
      </v-col>

      <v-col cols="6">
        <p class="text-white" style="color: #00a699;">RENTABILIDADE</p>
        <div style="border-bottom: 2px solid #00a699; width: 5rem; margin-bottom: 10px;"></div>
        <v-card style="background-color: #001f3f; margin-right: 3rem;">
          <v-card-text>
            <div style="display: flex; justify-content: center;">
              <span style="color: #31769e">Valor Total do empréstimo:</span>
            </div>
            <div style="display: flex; justify-content: center;">
              <p style="font-size: 2rem; color: white;">{{ numberToReal(Number(business.valor)) }}</p>
            </div>
            <v-row>
              <v-col>
                <div style="display: flex; justify-content: center; margin-top: 0.5rem">
                  <span style="color: #31769e">Taxa de retorno ao investidor::</span>
                </div>
                <div style="display: flex; justify-content: center;">
                  <p style="font-size: 2rem; color: white;">{{ numberToPercentage(Number(business.taxa)) }}</p>
                  <span style="color: white; font-size: 1.4rem; margin-left: 10px;"></span>
                </div>
              </v-col>
              <v-col>
                <div style="display: flex; justify-content: center; margin-top: 0.5rem">
                  <span style="color: #31769e">Prazo de operação:</span>
                </div>
                <div style="justify-content: center; display: flex; ">

                  <p style="font-size: 2rem; color: white;">{{ business.prazo }}</p>
                  <p style="font-size: 0.5rem; color: white;">{{ business.carencia }}</p>
                  <span style="color: white; font-size: 1.4rem; margin-left: 10px;">meses</span>
                </div>
              </v-col>
            </v-row>
            <div style="display: flex; justify-content: center; margin-top: 0.5rem">
              <span style="color: #31769e">Investimento Mínimo:</span>
            </div>
            <div style="display: flex; justify-content: center;">
              <p style="font-size: 2rem; color: white; ">{{ numberToReal(Number(business.valor_minimo)) }}</p>
            </div>
          </v-card-text>
        </v-card>
      </v-col>


      <v-col cols="3" style="height: auto; margin-top: -2rem">
        <v-card style="height: auto;" @click="triggerFileInput">
          <v-img :width="400" :height="200" contain :src="getPhoto(business.logo)">
          </v-img>
        </v-card>
      </v-col>

      <v-col cols="9" style="height: auto; margin-top: -2rem ">
        <v-card style="background-color: #edf0f0; height: 100%; margin-right: 3rem;">
          <div style="display: flex; margin-left: 2rem; justify-content: start; align-items: start; height: 100%;">
            <p style="color: #001f3f; font-weight: 600; margin-right: 1rem; margin-top: 2rem;">Uso consciente do
              dinheiro:</p>
            <p style="font-size: 2rem; color: white;">{{ business.destaque }}</p>
          </div>
        </v-card>
      </v-col>

      <!-- <v-col cols="3">
        <v-card style="height: 11.7rem; cursor: pointer;" @click="triggerFileInput">
          <v-file-input style="display: none;" ref="fileInput" label="" outlined dense hide-details></v-file-input>
          <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
            <p style="color: #001f3f; font-weight: 600;">Clique para adicionar arquivo</p>
          </div>
        </v-card>
      </v-col>

      <v-col cols="3">
        <v-card style="height: 11.7rem; cursor: pointer;" @click="triggerFileInput">
          <v-file-input style="display: none;" ref="fileInput" label="" outlined dense hide-details></v-file-input>
          <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
            <p style="color: #001f3f; font-weight: 600;">Clique para adicionar arquivo</p>
          </div>
        </v-card>
      </v-col> -->



      <v-col cols="12">
        <v-card style="background-color: #edf0f0; margin-left: 3rem; margin-right: 3rem;">
          <div style="display: flex; justify-content: start; margin-left: 2rem;">
            <!-- {{ business }} -->
            <p style="color: #001f3f; font-weight: 600; margin-top: 1.5rem; margin-bottom: 1.5rem;">QUEM É</p>
          </div>
          <div style="display: flex; justify-content: center;">
            <p style="margin-left: 2rem; color: #001f3f">{{ business.quem_e }}</p>

          </div>
        </v-card>
      </v-col>



      <v-col cols="12">
        <v-card style="background-color: #edf0f0; margin-left: 3rem; margin-right: 3rem;">
          <div style="display: flex; justify-content: start; margin-left: 2rem;">
            <p style="color: #001f3f; font-weight: 600; margin-top: 1.5rem; margin-bottom: 1.5rem;">QUEM FAZ</p>
          </div>
          <div style="display: flex; justify-content: center;">
            <p style="margin-left: 2rem; color: #001f3f">{{ business.quem_faz }}</p>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card style="background-color: #edf0f0; margin-left: 3rem; margin-right: 3rem;">
          <div style=" display: flex; justify-content: start; margin-left: 2rem;">
            <p style="color: #001f3f; font-weight: 600; margin-top: 1.5rem; margin-bottom: 1.5rem;">IMPACTO</p>
          </div>
          <div style="display: flex; justify-content: center;">
            <p style="margin-left: 2rem; color: #001f3f">{{ business.impacto }}</p>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12">
        <div style="display: flex; justify-content: center;">
          <v-btn style="background-color: #14ac94; padding: 0rem 3.5rem 0rem 3.5rem" rounded>
            <span style="color: white; font-size: 1.2rem">
              INVISTA AGORA!
            </span>
          </v-btn>
        </div>
      </v-col>


      <v-col cols="12" style="margin-top: -2rem;">
        <div style="margin-right: 3rem; margin-left: 3rem; margin-top: 2rem; display: flex; margin-bottom: 3rem;">
          <v-card style="width: 100%; background-color: #b3e3f9">
            <div style="display: flex; justify-content: center;">
              <p style="color: #35558f; margin-top: 10px; margin-bottom: 10px;">CONTRIBUIÇÃO PARA O SETOR</p>
            </div>
            <p class="mx-2">{{ business.pq_apoiamos }}</p>
          </v-card>
        </div>
      </v-col>


      <v-col cols="12" style=" margin-top: -3rem; margin-bottom: -2rem;">
        <div style="display: flex; justify-content: center; align-items: center">
          <div style="margin-left:3rem; border-bottom: 2px solid #00a699; width: 100%; margin-bottom: 10px;"></div>
          <div>
            <!-- {{ business.titular.razao_social_nome }} -->
            <p style="color: #00a699; width: 18vw; margin-left: 1rem;  margin-right: 1rem;">{{
              business.titular.razao_social_nome }}</p>
          </div>
          <div style="margin-right: 3rem; border-bottom: 2px solid #00a699; width: 100%; margin-bottom: 10px;"></div>
        </div>
      </v-col>

      <v-col cols="12">
        <v-card style="width: 92%; background-color: #1b2b5b; margin-left: 3rem;">
          <div style="display: flex; align-items: center;">
            <p style="color: #00a699; margin-left: 2rem; margin-top: 10px; margin-bottom: 10px;">IMPACTO SOCIOAMBIENTAL DECLARADO</p>
            <p style="color: white; font-size: 1rem; margin-left: 0.5rem;margin-top: 10px;margin-bottom: 10px;">{{
              business.impacto_socioambiental }}</p>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12">
        <div style="margin-top: 2rem; display: flex; justify-content: space-between; margin: 3rem 3rem 3rem 3rem;">
          <v-card style="width: 49%; background-color: #edf0f0">
            <div style="display: flex; margin-left: 2rem;">
              <p style="font-weight: 600; color: #35558f; margin-top: 10px; margin-bottom: 10px;">BOAS PRÁTICAS: </p>
            </div>
            <div style="display: flex; justify-content: center;">
              <p style="font-size: 1rem; margin-left: 2rem;">{{ business.boas_praticas }}</p>
            </div>
          </v-card>
          <v-card style="width: 49%; background-color: #edf0f0">
            <div style="display: flex; margin-left: 2rem;">
              <p style="font-weight: 600; color: #35558f; margin-top: 10px; margin-bottom: 10px;">OBJETIVO:</p>
            </div>
            <div style="display: flex; ">
              <p style="font-size: 1rem; margin-left: 2rem;">{{ business.objetivo }}</p>
            </div>
          </v-card>
        </div>
      </v-col>

      <v-col cols="12">
        <p style="margin-left: 3rem; margin-bottom: -2rem; margin-top: -4rem;">Espaço para gráfico</p>
        <div style="margin-top: 1rem; display: flex; justify-content: space-between; margin: 3rem;">
          <v-card style="width: 49%; height: 16rem; cursor: pointer;" @click="triggerFileInput">
            <!-- {{ business.grafico }} -->
            <v-img :width="400" :height="300" contain :src="getPhoto(business?.grafico)">
            </v-img>
          </v-card>
          <v-card style="width: 49%; background-color: #edf0f0">
            <div style="display: flex; margin-left: 2rem;">
              <p style="font-weight: 600; color: #35558f; margin-top: 10px; margin-bottom: 10px;">OPINIÃO DE CRÉDITO:
              </p>
            </div>
            <div style="display: flex;">
              <p style="font-size: 1rem; margin-left: 2rem;">{{ business.opiniao_credito }}</p>
            </div>
          </v-card>
        </div>
      </v-col>


      <v-col cols="12" style="margin-top: -4rem;">
        <div style="margin-top: 1rem; display: flex; margin-bottom: 3rem; margin-left: 3rem; margin-right: 3rem;">
          <v-card style="width: 100%; background-color: #b3e3f9">
            <div style="display: flex; justify-content: center;">
              <p style="color: #35558f; margin-top: 10px; margin-bottom: 10px;">GARANTIAS</p>
            </div>
            <p class="mx-2">{{ business.garantias }}</p>
          </v-card>
        </div>
      </v-col>

    </v-row>



    <v-row>
      <v-col cols="12" class="text-center">
        <v-btn style="background: #000; color: #fff" @click="generatePDF" class="invest-btn" large>GERAR PDF</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getPhoto, numberToReal, numberToPercentage } from "@/js/belatUtils";
// Importando o html2pdf
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import businessMixin from "@/mixins/businessMixin";
import userMixin from "@/mixins/userMixin";
export default {
  mixins: [businessMixin, userMixin],
  data() {
    return {
      business: null,
      userId: localStorage.getItem("userId"),
      token: localStorage.getItem("token"),
      urlEnv: process.env.VUE_APP_SERVICE_URL,
    };
  },
  async created() {
    const businessId = this.$route.query.id;
    // this.teste()
    if (businessId) {
      await this.loadBusiness(businessId);
      console.log(this.business)
    }
  },
  mounted() {
    console.log(getPhoto)
  },
  computed: {
    numberToPercentage() {
      return numberToPercentage
    },
    numberToReal() {
      return numberToReal;
    },
    getPhoto() {
      return getPhoto;
    },
  },
  methods: {
    formatTaxa(value) {
      const taxa = Number(value); // Converte para número
      if (isNaN(taxa)) return "0"; // Garante um fallback se o valor não for válido
      return taxa.toFixed(1).replace('.', ','); // Formata o número
    },
    triggerFileInput() {
      this.$refs.fileInput.$el.click();
    },

    async generatePDF() {
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfContent = this.$refs.pdfContent;
      const canvas = await html2canvas(pdfContent, { scale: 2, useCORS: true });

      const imgWidth = 210;
      const pageHeight = 297;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;
      while (heightLeft > 0) {
        const imgData = canvas.toDataURL('image/png');
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        if (heightLeft > 0) {
          pdf.addPage();
          position = -pageHeight;

        }
      }
      pdf.save("documento.pdf");
    },




    async loadBusiness(businessId) {
      try {
        await this.getBusinessDetail(businessId); // Usa o mixin
        this.business = this.negocio; // Os dados ficam disponíveis no `negocio`
      } catch (error) {
        console.error("Erro ao carregar dados do negócio:", error);
      }
    },
    // teste(businessId) {
    //   this.getBusinessDetail(businessId);
    // }
  }
};
</script>

<style scoped>
/* Configurações de margens e layout para PDF */


body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.header {
  background-color: #001f3f;
  padding: 16px;
}

/* Impacto Positivo Card */
.impact-card {
  background-color: #00a699;
  color: white;
  padding: 16px;
}

/* Rentabilidade Card */
.profitability-card {
  background-color: #001f3f;
  color: white;
  padding: 16px;
}

/* Detalhes Card */
.details-card {
  background-color: #f9f9f9;
  color: #333;
  padding: 16px;
}

.invest-btn {
  background-color: #00a699;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none
}

.v-textarea {
  white-space: pre-wrap;
  /* Mantém as quebras de linha */
  overflow: visible !important;
  /* Garante que o conteúdo extra seja visível */
  word-wrap: break-word;
  /* Quebra palavras longas */
}
</style>
