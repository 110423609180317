import ConvertPDF from "./convertPDF.vue"

const routes = {
  children: [
    {
      name: "ConvertPDF",
      path: "convert-pdf",
      component: ConvertPDF,
      props: (route) => ({ businessId: route.query.id }), // Apenas o ID é passado
      meta: {
        requiresAuth: true
      }
    }
  ]
};

export default routes;
