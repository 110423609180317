<template>
    <div>
      <layout style="z-index: 100; position: relative;display: flex; flex-direction: column; min-height: 100vh;">
        <template v-slot:content>   
          <div style="color: #032a5a;" class="px-16 pt-3">
                <h1 class="userName" style="font-size: 18px; font-weight: 700">{{ $t('hello') }}, {{ user.name }}</h1>
                <p class="userName" style="font-size: 12px; font-weight: 500">{{ $t('welcome') }} {{ $t('to') }} Banca ética</p>
              </div>
          <div class="mt-lg-6 mt-sm-1 mx-4 py-auto px-sm-10 px-xs-2">
            <v-row>
              <v-col class="col-xs-12 col-sm-6">
                    <v-img src="@/assets/belat_app_02.png" width="80%" max-width="300px" min-width="150px" style="object-fit: cover;" class="mx-auto">
                        <v-row class="align-end d-flex justify-center pt-4 px-6" style="height:90%;">
                            <div class="px-16 px-xs-5">
                                <span class="white--text font-weight-bold">CROWDLENDING</span>
                              <p class="mb-1 white--text textHome2" style="font-size: 12px;">Investimento de impacto coletivo direto em uma empresa
                              </p>
                              <v-row class="d-flex justify-center align-end textHome">
                                <div>
                                  <v-btn style="width: 15rem" @click="$router.push('/mobile/MobileBusiness')" rounded class="d-flex justify-center mt-4 textHome" color="primary"
                                    dark >
                                    <span style="font-size: 8px;">Invista AGORA EM IMPACTO POSITIVO</span>
                                    <v-list-item-avatar size="30" class="opacityButton d-flex align-end ">
                                      <v-icon>mdi-chevron-right</v-icon>
                                    </v-list-item-avatar>
                                  </v-btn>
                                </div>
                              </v-row>
                                
                            </div>
                      </v-row>
                    </v-img>
              </v-col>
              <v-col class="col-xs-12 col-sm-6">
                <v-img src="@/assets/belat_app_01.png" width="80%" max-width="300px" min-width="150px" style="object-fit: cover;" class="mx-auto">
                    <v-row class="align-end d-flex justify-center pt-4" style="height:90%;">
                        <div class="px-16">
                            <span style="font-size: 15px;" class="white--text font-weight-bold">FUNDO BELAT <br>BRASIL </span>
                          <p class="text-start white--text textHome2" style="font-size:12px; margin-bottom: 0;">Investimento em fundo de Direitos Creditórios (FIDC)</p>
                          <p class="text-start white--text textHome2" style="font-size:10px; margin-bottom: 0;">*Disponivel para investidores qualificados</p>
                          <v-row class="d-flex justify-center align-end">
                              <v-btn  @click="returnFIDC" rounded class="d-flex justify-center mt-4 textHome" style="width: 15rem" color="primary"
                                dark >
                                <span style="font-size: 9.5px;" class="pl-7 mr-xs-1 textHome">CONHEÇA e faça parte</span>
                                  <v-list-item-avatar size="30" class="opacityButton2 ml-10">
                                    <v-icon>mdi-chevron-right</v-icon>
                                  </v-list-item-avatar>
                              </v-btn>
                            </v-row>
                            
                        </div>
                  </v-row>
                </v-img>
          </v-col>
            </v-row>
        </div>   
        <div class="teste" style="background: #032A5A; width: 100%;">
            <div class="px-2 pt-2 d-flex justify-center align-center">
                <p style="font-size: 14px" class="white--text text-center">Descubra outras formas de apoiar o negócio da Banca Ética Latam, entre em contato conosco</p>
            </div>
        </div>     
    </template>
</layout>
    </div>
  </template>
  
  <script>
  import layout from "@/layouts/internalLayoutMobile.vue";
  import userMixin from "@/mixins/userMixin";
  import axios from "axios";
  export default {
    metaInfo() {
    return {
      title: 'BELat - Home'
    }
  },
    components: {
      layout,
    },
    mixins: [userMixin],
    data() {
      return {
        tabs: null,
        tabNames: ['Dados pessoais', 'Informações adicionais', 'Declarações'],
        text: '',
        userId: localStorage.getItem("userId"),
        user: {
          name: null,
          email: null,
          phone_number: null,
          profile_photo_url: null,
        },
        currentScreenIndex: 0,
        token: localStorage.getItem("token"),
        urlEnv: process.env.VUE_APP_SERVICE_URL,
        items: [
          {
            title: "Meus Investimentos",
            icon: "mdi-home-city",
            route: "/user/MyInvestiments",
          },
          { title: "Meus dados", icon: "mdi-account", route: "/user/MyInfo" },
        ],
        right: null,
      };
    },
    methods: {
      removeNonNumeric(value) {
      return value?.replace(/\D/g, "");
    },
      returnFIDC() {
      
      const userData = {
        name: this.user.name,
        phone_number: this.removeNonNumeric(this.user.phone_number),
        email: this.user.email,
        origem_lead: 'FIDC'
      };

      axios
        .post(this.urlEnv + 'createLead', userData, {headers: {'X-Frontend-URL': window.location.origin}})
        .then(() => {
            console.log("Enviado")
        })
        .catch((err) => {
          this.disabled = false;
          console.log(err)
        }).finally(() => {
          this.$router.push({
              name: "MobileFIDC",
            });
        });
      },
      setCurrentScreen(index) {
        this.currentScreenIndex = index;
      },
    },
    created() {
      this.getUserData();
    },
    computed: {
      currentScreen() {
        return this.screens[this.currentScreenIndex];
      },
      totalScreens() {
        return this.screens.length;
      },
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly;
      },
    },
  };
  </script>
  
  <style scoped>

  .v-navigation-drawer__border {
    display: none;
  }
  
  .rounded-card {
    border-radius: 20px;
    border: 1px solid #032a5a
  }
  
  .rounded-card-2 {
    border-radius: 20px;
    border: 1px solid #DF6E8B
  }
  
  .rounded-card-3 {
    border-radius: 20px;
    border: 1px solid #14aa95;
  }
  
  .opacityButton{
    background: #14aa95;
  }

  .opacityButton2 {
    background: #DF6E8B;
  }
  
  .oi {
    border-bottom: 10px solid black;
  }
  
  .text-h4,
  .text-xl-h4,
  .text-xl-h5,
  .text-h3,
  .text-h5 .text-h6,
  .white--text,
  .text-center,
  .d-flex {
      font-family: 'Raleway', cursive !important;
  }
  
  @media (min-width: 2560px) {
    .textHome {
      font-size: 24px; 
    }
    .textHome2 {
      font-size: 21px; 
    }
  }
  @media (min-width: 1920px) and (max-width: 2559px) {
    .textHome {
      font-size: 17px !important; 
    }
    .textHome2 {
      font-size: 21px; 
    }
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .textHome {
      font-size: 14px; 
    }
    .textHome2 {
      font-size: 21px; 
    }
  }
  @media (max-width: 1279px) {
    .textHome {
      font-size: 12px; 
    }
    .textHome2 {
      font-size: 16px; 
    }
  }

  /* .teste {
    position: fixed;
    bottom: 0;
  } */
  </style>

  <style>
.userName {
    font-family: 'Raleway', cursive !important;
}
</style>